import { render, staticRenderFns } from "./EmbedDescriptionModal.vue?vue&type=template&id=566c5d32&scoped=true&"
import script from "./EmbedDescriptionModal.vue?vue&type=script&lang=js&"
export * from "./EmbedDescriptionModal.vue?vue&type=script&lang=js&"
import style0 from "./EmbedDescriptionModal.vue?vue&type=style&index=0&id=566c5d32&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566c5d32",
  null
  
)

export default component.exports