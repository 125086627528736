<template>
    <div class="share-embed-code" style='position: relative;'>
        <div class="restriction-dialog" v-if='restricted'>
            <div class="warn-sign"><img src="../../../../../../../../static/img/exclamation.svg" alt=""></div>
            <p>Your current plan does not include Link video embed.</p>
            <p>Please upgrade to one of our premium plans to get access to this feature.</p>
            <el-button round class="restriction_dialog_btn" type="success" @click="$emit('upgrade')"> See Upgrade
                Options
            </el-button>
        </div>
        <div class="share-embed-head" v-if="this.videoData.is_audio == 0 && this.videoData.drm == 0">
            <p class="title-small">MP4</p>
            <input type="text" class='video-link' v-model='mp4Link'>
            <span class="copy-link" @click="copyToClipboard('mp4')">Copy</span>

        </div>
        <div class="share-embed-head" v-if="this.videoData.is_audio == 0    ">
            <p class="title-small">M3U8</p>
            <input type="text" class='video-link' v-model='m3u8Link'>
            <span class="copy-link" ref="m3u8" @click="copyToClipboard('m3u8')">Copy</span>
        </div>
        <div class="share-embed-head" v-if="this.videoData.is_audio == 1 && this.videoData.drm == 0">
            <p class="title-small">MP3</p>
            <input type="text" class='video-link' v-model='mp3Link'>
            <span class="copy-link" ref="mp3" @click="copyToClipboard('mp3')">Copy</span>
        </div>
        <div class="share-embed-head" v-if="this.videoData.is_audio == 1 && this.videoData.drm == 1">
            <p class="title-small">M3U8</p>
            <input type="text" class='video-link' v-model='mp3Link'>
            <span class="copy-link" ref="mp3" @click="copyToClipboard('mp3')">Copy</span>
        </div>

    </div>
</template>
<style scoped>
    .video-link {
        width: 85%;
        height: 38px;
        border: 1px solid #E2E5ED;
        border-radius: 5px;
        margin-top: 15px;
    }

    .copy-link {
        margin-left: 15px;
        color: #33acdb;
        font-size: 17px;
        cursor: pointer;
    }

    .share-embed-head .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        opacity: 1;
        color: #FFFFFF;
    }

    .title-small {
        margin-top: 6px;
        font-family: Helvetica Neue;
        font-size: 15px;
        letter-spacing: 0px;
        color: #21455E;
        text-transform: capitalize;
        opacity: 1;
    }

    .title {
        text-align: left;
        font-family: Helvetica Neue;
        font-size: 19px;
        color: #21455E;
        opacity: 1;
    }

    .description-text {
        color: rgba(33, 69, 94, 0.664);
        font-size: 14px;
        text-align: justify;
    }

    .link-description-text {
        color: rgba(33, 69, 94, 0.664);
        font-size: 14px;
    }

    .link-container {
        background: #EBF9FA;
        width: 270px;
        height: 30px;
        padding: 5px;
        margin-top: 28px;
        border-radius: 10px;
    }

    .link-buttons {
        display: flex;
        flex-direction: column;
        width: 90px;
    }

    .share-link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 469px;
    }

    .link-buttons .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        font-weight: 200;
        opacity: 1;
        color: #FFFFFF;
        margin-top: 15px;
    }

    .link-url, .link-icon {
        margin-left: 5px;
        color: #00ACDC;
    }

    .unchecked {
        width: 17px;
        height: 17px;
        border: 2px solid #D8DCE6;
        border-radius: 3px;
    }

    .link-time {
        margin-top: 30px;
        margin-left: 15px;
    }

    .link-time-description {
        color: #21455E;
        margin-left: 15px;
        font-size: 13px;
    }

    .timer-label {
        height: 38px;
        background: #F5F6F9;
        padding: 0 15px;
        color: #21455E;
        line-height: 2.5;
        border-radius: 5px 0 0 5px;
        margin-top: 15px;
    }

    .video-timer-labels {
        display: flex;
        flex-direction: column;
    }

    .video-timer input {
        height: 38px;
        border: 1px solid #E2E5ED;
        text-align: center;
        margin-top: 15px;
    }

    .time-controls {
        margin-left: 45px;
    }

    .link-publish {
        margin-top: 50px;
    }

    .clipped {
        width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .minutes {
        border-right: none !important;
        width: 50px;
        text-align: right !important;
    }

    .seconds {
        border-left: none !important;
        width: 50px;
        text-align: left !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .time-seperator {
        height: 38px;
        margin-top: 15px;
        padding-top: 8px;
        font-weight: 700;
        border-top: 1px solid #E2E5ED;
        border-bottom: 1px solid #E2E5ED;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .restriction-dialog {
        font-size: 14px;
        width: 100%;
        display: flex;
        background: rgba(1, 26, 34, 0.822);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 95;
        border-radius: 12px;
        backdrop-filter: blur(8px);
    }

    .restriction-dialog p {
        padding: 2px;
        color: #fff;
    }

</style>
<script>
    export default {
        props: ['videoData', 'restricted'],
        name: 'EmbedFileLink',
        data: () => ({
            mp4Link: '',
            m3u8Link: '',
            mp3Link: '',
        }),
        created() {
            console.log(this.videoData, 'videoData');
            this.m3u8Link = this.videoData.path
            this.m3u8Link = this.m3u8Link.replace("s3.us-east-2.wasabisys.com/adilo-encoding", "stream.adilo.com/adilo-encoding");
            this.m3u8Link = this.m3u8Link.replace("adilo-encoding.stream.adilo.com", "stream.adilo.com/adilo-encoding");

            if (this.videoData.is_audio == 0) {

                if(typeof this.videoData.files == "string"){
                    var files = JSON.parse(this.videoData.files);
                }else{
                    var files = this.videoData.files;
                }
                console.log(files);
                files.pop();
                files.pop();

                var length = files.length;

                this.mp4Link = files[length - 1];
                this.mp4Link = this.mp4Link.replace("s3.us-east-2.wasabisys.com/adilo-encoding", "stream.adilo.com/adilo-encoding");
                this.mp4Link = this.mp4Link.replace("adilo-encoding.stream.adilo.com", "stream.adilo.com/adilo-encoding");
            }
            if (this.videoData.is_audio == 1) {

                this.mp3Link = this.videoData.path
                this.mp3Link = this.mp3Link.replace("s3.us-east-2.wasabisys.com/adilo-encoding", "stream.adilo.com/adilo-encoding");
                this.mp3Link = this.mp3Link.replace("adilo-encoding.stream.adilo.com", "stream.adilo.com/adilo-encoding");
            }
            console.log(this.videoData, 'videoData ');

        },
        methods: {
            copyToClipboard(type) {

                if (type == 'mp4') {
                    var url = this.mp4Link;
                }

                if (type == 'mp3') {
                    var url = this.mp3Link;
                }

                if (type == 'm3u8') {
                    var url = this.m3u8Link;
                }


                if (url) {
                    let el = document.createElement('textarea');
                    el.value = url;
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                }
            },
        },
        watch: {}
    }
</script>