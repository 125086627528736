<template>
    <div class="modal">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">{{ experiment ? "EMBED EXPERIMENT" : "SHARE & EMBED"}}</div>
                <div class="modal-close" @click='closeModal'><i class="fas fa-times"></i></div>
            </div>
            <div class="modal-body">
                <div class="modal-sidebar">
                    <EmbedModalSidebar
                            :videoData='videoData'
                        @section='triggerSection'
                    ></EmbedModalSidebar>
                </div>
                <div class="modal-sections">
                    <span v-if='section=="inline"'><EmbedModalInline :videoData='videoData' :summaryPage='false' @closeModal='closeModal' @copySuccess='showSuccessPopup'></EmbedModalInline></span>
                    <span v-else-if='section=="popover"'>
                        <EmbedPopover
                            :restricted='showRestrictionModal.popover'
                            :videoData='videoData'
                            @copySuccess='showSuccessPopup'
                            @upgrade='showUpgradeOptions'></EmbedPopover>
                    </span>
                    <span v-else-if='section=="email"'>
                        <EmbedEmailCampaign
                            :restricted='showRestrictionModal.email'
                            :video='video'
                            :videoData='videoData'
                            :fullVideo='fullVideoData'
                            @copySuccess='showSuccessPopup'
                            @upgrade='showUpgradeOptions'></EmbedEmailCampaign></span>
                    <span v-else-if='section=="share"'>
                        <EmbedShareLink :restricted='showRestrictionModal.link' :videoData='videoData' @copySuccess='showSuccessPopup'></EmbedShareLink>
                    </span>
                    <span v-else-if='section=="links"'>
                        <EmbedFileLink :restricted='showRestrictionModal.link' :videoData='videoData' @copySuccess='showSuccessPopup'></EmbedFileLink>
                    </span>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
        <SuccessPopup
            v-if='successPopup'
            title='Share Link Copied'
            message='' 
            style='position: fixed; left: 20px; bottom: 20px;'
        ></SuccessPopup>
    </div>
</template>

<style scoped>
    .modal {
        position: fixed;
        width: 100vw;
        height: 100vw;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(1, 26, 34, 0.301);
        z-index: 2000;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            top: -10px;
        }
        to {
            opacity: 1;
            top: 0;
        }
    }

    .modal-content {
        position: absolute;
        width: 637px;
        height: max-content;
        margin: auto;
        left: 0;
        right: 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #00000014;
        border: 1px solid #0BACDB;
        border-radius: 12px;
        opacity: 1;
        animation: fadeIn 300ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
        /* overflow: scroll; */
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px;
        color: #21455E;
        border-bottom: 1px solid #00000014;
    }

    .modal-header .modal-close {
        cursor: pointer;
    }

    .modal-header .modal-close i {
        color: #A3BAC6;
    }

    .modal-header .modal-title {
        position: relative;
        left: 50%;
        margin-left: -50px;
    }

    .modal-sections {
        margin-left: 102px;
        padding: 25px 25px;
    }

    ::-webkit-scrollbar { width: 20px; }
    ::-webkit-scrollbar-track { background: rgb(255, 255, 255); }
    ::-webkit-scrollbar-thumb {
        background: #C9EDF8;
        background-clip: padding-box;
        border: 15px solid rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb:hover { background: #C9EDF8; }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }


</style>

<script>

import EmbedModalSidebar from './EmbedModalComponents/EmbedModalSidebar';
import EmbedModalInline from './EmbedModalComponents/Sections/EmbedModalInline';
import EmbedPopover from './EmbedModalComponents/Sections/EmbedPopover';
import EmbedEmailCampaign from './EmbedModalComponents/Sections/EmbedEmailCampaign';
import EmbedShareLink from './EmbedModalComponents/Sections/EmbedShareLink';
import EmbedFileLink from './EmbedModalComponents/Sections/EmbedFileLink';
import SuccessPopup from '../../../../Common/SuccessPopup';

export default {
    props: ['experiment', 'video'],
    name: 'EmbedModal',
    data: () => ({
        section: 'inline',
        videoData: {
            url: '',
            video_id: '',
            title: '',
            description: '',
            embed_url: '',
            duration: '',
            thumbnail_url: ''
        },
        successPopup: false,
        showRestrictionModal: {
            email: false,
            popover: false,
            link: false,
        },
        loading: false,
        fullVideoData: false,
    }),
    components: {
        EmbedModalSidebar,
        EmbedModalInline,
        EmbedPopover,
        EmbedEmailCampaign,
        EmbedShareLink,
        SuccessPopup,
        EmbedFileLink,
    },
    created()
    {

        let experiment = this.$props.experiment;
        this.getLifeTimePlanDetails();

        if (experiment) {
            if (experiment.type == 'Thumbnails') {
                this.videoData.url = experiment.video.embed_url;
                this.videoData.video_id = experiment.video.video_id;
                this.videoData.video_id_string = experiment.video.video_id;
                this.videoData.id = experiment.video.id;
                this.videoData.title = experiment.video.title;
                this.videoData.description = experiment.video.description;
                this.videoData.embed_url = experiment.video.embed_url;
                this.videoData.duration = experiment.video.duration_formatted;
                this.videoData.duration_raw = experiment.video.duration;
                this.videoData.files = experiment.video.files;
                this.videoData.thumbnail_url = experiment.video.thumbnail;
                this.videoData.experiment_type = 'thumbnails';
                this.videoData.is_audio = experiment.is_audio;

            } else {
                let random = Math.round(Math.random());
                this.videoData.url = `${process.env.VUE_APP_BACKEND_URL}watch/${experiment.id}?ex=1`;
                this.videoData.video_id = experiment.id;
                this.videoData.embed_url = `${process.env.VUE_APP_BACKEND_URL}watch/${experiment.id}?ex=1`;
                this.videoData.videos = {a: experiment.video_a, b: experiment.video_b};
                this.videoData.files = [experiment.video_a, experiment.video_b][random].files;
                this.videoData.owner = experiment.video_a.owner;
                this.videoData.id = [experiment.video_a.id, experiment.video_b.id][random];
                this.videoData.thumbnail_url = [experiment.video_a.thumbnail, experiment.video_b.thumbnail][random];
                // this.videoData.video_id_string = experiment.video_a.video_id;
                // this.videoData.title = experiment.video_a.title;
                // this.videoData.description = experiment.video_a.description;
                // this.videoData.duration = experiment.video_a.duration_formatted;
                // this.videoData.thumbnail_url = experiment.video_a.thumbnail;
                this.videoData.experiment_type = 'videos';
            }
        } else if (this.$props.video) this.populateVideo(this.$props.video);
        // console.log(this.videoData);
        // const videoId = this.videoData.id ? this.videoData.id : this.videoData.video_id;
        // if (videoId) {
        //     this.axios.get(`/video/full/${videoId}`)
        //         .then(response => {
        //             if (response.data.success) {
        //                 this.fullVideoData = response.data.video;
        //             }
        //         })
        //         .catch(error => console.log(error));
        // }
    },
    methods: {
        triggerSection(section)
        {
            this.section = section;
        },
        closeModal()
        {
            this.$emit('trigger', false);
        },
        back()
        {
            this.$emit('trigger', true);
        },
        populateVideo(video)
        {
            console.log(video, 'video.power_player');
            this.videoData.url = video.embed_url;
            this.videoData.video_id = video.video_id;
            this.videoData.video_id_string = video.video_id;
            this.videoData.title = video.title;
            this.videoData.description = video.description;
            this.videoData.embed_url = video.embed_url;
            this.videoData.duration = video.duration_formatted;
            this.videoData.duration_raw = video.duration;
            this.videoData.thumbnail_url = video.thumbnail;
            this.videoData.experiment_type = 'thumbnails';
            this.videoData.is_audio = video.is_audio;
            this.videoData.path = video.drm_protection == 0 ? video.path : video.hls_url_aes;
            this.videoData.drm = video.drm_protection;
            this.videoData.files = video.files;
            this.videoData.power_player = video.power_player;
        },
        showSuccessPopup()
        {
            this.successPopup = true;
            setTimeout(() => {
                if (this.successPopup) this.successPopup = false;
            }, 5000);
        },
        getLifeTimePlanDetails()
        {
            this.loading = true;
            this.axios.get('restrictions/index')
                .then(response => {
                    console.log(response.data);
                    this.loading = false;
                    if (response.data.lifetime && response.data.lifetime.modules.length) {
                        const content = response.data.lifetime.content;
                        console.log(content);
                        this.showRestrictionModal.popover = !content.popover_embed;
                        this.showRestrictionModal.email = !content.email_embed;
                        this.showRestrictionModal.link = !content.link_embed;
                    } else {

                    }
                })
                .catch(error => {
                    this.loading = false;
                })
        },
        showUpgradeOptions() {
            this.$router.push({name: 'ChangeSubscriptionPlan', params: {'section': 'billing'}});
        },
    }
}
</script>